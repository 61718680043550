body {
    background: rgba(253, 253, 255, 1);
}

.forget {
    display: flex;
}

.site-logo {
    padding-top: 15px;
    padding-left: 30px;
}

.forget-circle {
    background: url('../../../public/login-circle.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: 63%;
}

.left-forget {
    width: 45%;
    background: linear-gradient(90deg, #3bc9af9a 0%, #6078ff8e 100%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left-forget .hero-animation {
    height: 100%;
    overflow: hidden;
}

.left-forget .hero-animation img {
    max-height: 100%;
    margin-left: -10%;
}

.left-forget .hero-animation {
    display: flex;
    justify-content: center;
}

.nav-link {
    display: inline-flex;
    align-items: center;
    gap: 15px;
}

.nav-link img {
    width: 50px;
    height: 50px;
}

.nav-link span {
    font-size: 25px;
}

.grow-your-business {
    padding: 0 70px;
    margin-top: 20px;
    padding-bottom: 70px;
}

.grow-your-business img {
    width: 100%;
    flex-shrink: 0;
    object-fit: contain;
}

.right-forget {
    width: 55%;
    height: 100vh;
    overflow-y: auto;
    padding: 100px 0;
    display: flex;
    align-items: center;
    margin: auto;
}

.right-forget-wrapper {
    margin: auto;
    width: 420px;
}

.forget-form {
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.06);
    padding: 25px;
    border-radius: 32px;
    background: white;
}

.forget-form h6 {
    font-size: 30px;
    font-weight: 700;
}

.forget-form .forget-title {
    color: rgb(113, 23, 165);
    margin-bottom: 30px;
}

.forget-form .forget-title a {
    color: rgba(96, 121, 255, 1);
}

.form-control {
    border: rgba(223, 223, 223, 1) !important;
    box-shadow: none !important;
}

.form-control:focus {
    border: 1px solid rgba(96, 121, 255, 1);
}

.form-control::placeholder {
    color: rgba(0, 0, 0, 0.35);
}

.forget .submit-btn {
    background: rgba(96, 121, 255, 1);
    color: #FFF;
    border: none;
    outline: none;
    width: 100%;
    padding: 10px 30px;
    font-weight: 500;
    border-radius: 500px;
    margin-top: 30px;
}

.terms-condition {
    font-size: 14px;
    text-align: center;
    margin-top: 25px;
    color: #5f5f5f;
}

.terms-condition a {
    color: black;
}

.forgot-password {
    color: rgba(96, 121, 255, 1);
}

@media (max-width: 575.98px) {
    .left-forget {
        display: none;
    }

    .forget-circle {
        background-size: 150%;
    }

    .right-forget {
        width: 100%;
        padding: 40px 10px;
    }

    .right-forget-wrapper {
        width: 100%;
    }
}

@media (max-width: 767px) and (min-width: 576px) {
    .left-forget {
        display: none;
    }

    .forget-circle {
        background-size: 130%;
    }

    .right-forget {
        width: 100%;
        padding: 40px 10px;
    }

    .right-forget-wrapper {
        width: 450px
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .left-forget {
        display: none;
    }

    .forget-circle {
        background-size: 110%;
    }

    .right-forget {
        width: 100%;
        padding: 40px 10px;
    }

    .right-forget-wrapper {
        width: 450px;
    }
}

@media (min-width: 992px) {
    .small-screen-logo {
        display: none !important;
    }

    .grow-your-business {
        padding: 0 50px;
        margin-top: 20px;
        padding-bottom: 40px;
    }
}

@media (min-width: 1500px) {
    .grow-your-business {
        padding: 0 70px;
        padding-bottom: 70px;
    }

    .site-logo {
        padding-top: 25px;
        padding-left: 50px;
    }
}