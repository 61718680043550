@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

body {
  color: #0c0c0c;
  background-color: #FFFFFF;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

.bg-circle {
  background: url('../../public/bg-circle.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: 70%;
}

.home-title-img {
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.home-title-img .home-title-gradiant-xl {
  width: 100%;
  z-index: 1;
}

.home-title-img .overlay-img-xl {
  position: absolute;
  padding-top: 2.5px;
  left: -1px;
  width: 100%;
  mix-blend-mode: overlay;
}

.home-title-img .home-title-gradiant-sm {
  z-index: 1;
  display: none;
}

.home-title-img .overlay-img-sm {
  position: absolute;
  padding-top: 2.5px;
  left: -1px;
  mix-blend-mode: overlay;
  display: none;
}

.App {
  flex-direction: column;
  display: flex;
}

.layout_padding {
  padding: 75px 0;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
}

.layout_padding2-bottom {
  padding-bottom: 45px;
}

.layout_padding-top {
  padding-top: 75px;
}

.layout_padding-bottom {
  padding-bottom: 75px;
  padding-top: 75px;
}

.heading_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

.heading_container h2 {
  position: relative;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 54px;
  color: #000000;

}



/*header section*/
.hero_area {
  position: relative;
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.hero-text {
  font-size: 85px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  stroke-width: 2;
  text-anchor: middle;
}

.hero-text-span {
  position: relative;
  top: 10px;
}

.sub_page .hero_area {
  height: auto;
  background: none;
  background-color: #df0606;
}

.sub_page .footer_bg {
  background: none;
  background-color: #000000;
  padding-top: 45px;
}

.header_section .container-fluid {
  background-color: #FFFFFF;



  padding-right: 25px;
  padding-left: 25px;
}

.header_section .nav_container {

  margin: 0 auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 3px 15px;
  margin: 10px 15px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}



a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}


.navbar-brand {
  margin-left: 25px;
}


.custom_nav-container {
  z-index: 99999;
}

.navbar-expand-lg .navbar-collapse {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #FFFFFF;
  margin: 7px 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 0;
  margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
  display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin: 0;
  margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
  -webkit-transform: none;
  transform: none;
}

.quote_btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.quote_btn-container a {
  color: #FFFFFF;
  margin-right: 25px;
}

.quote_btn-container a img {
  width: 15px;
  margin: 0 25px;
}

/*end header section*/
/* slider section */
.slider_section {
  display: flex;
  align-items: center;
  color: #3b3a3a;
  background-image: url(../images/growbusiness.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  /* Change this to the desired percentage */
}

.slider_section .container {
  padding-top: 120px;
  padding-bottom: 120px;
  min-height: 580px;
  height: 100vh;
  max-height: 1000px;
}

.slider_section .container-lg {
  padding-left: 15%;
}

.slider_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider_section .detail_box h1 {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 85px;
  font-family: Arial;
}

.slider_section .detail_box h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: rgba(0, 0, 0, .65);
}

.contact-btn {
  display: inline-block;
  background: #6079FF;
  color: #FFF;
  padding: 15px 30px;
  border-radius: 999px;
  margin-top: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  transition: .3s;
}

.contact-btn:hover,
.contact-btn:focus,
.contact-btn:active {
  background: #8396FF;
  color: #FFF;
}




.about_section {
  color: black;
  background-image: url(../images/about_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  /* make the section relative */
}

.about_section .container {
  max-width: 1500px
}


.about_section .right {
  height: 100%;
  width: 700px;
}

.about_section .bg-image img {
  width: 700px;
}

.about_section .detail-box {
  margin-top: 10%;
  display: flex;
  flex-direction: column;

}

.about_section .detail-box p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  line-height: 140%;
  color: #000000;
  text-shadow: 0px 2px 8px rgba(255, 255, 255, 0.25);
}

.about_section .detail-box a {
  display: inline-block;
  padding: 10px 35px;
  background-color: #2C3E77;
  ;
  color: #FFFFFF;
  border-radius: 10px;
  margin: 25px 0 45px 0;
  height: 100%;
}


.about_section .detail-box ul {
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  padding-left: 0;
  /* remove default padding from the ul */
}

.about_section .detail-box ul li {
  position: relative;
  /* make the li element relative */
  list-style: none;
  /* remove default list-style */
  padding-left: 40px;
  /* add padding to the left of the li */
}

.about_section .detail-box ul li::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  /* adjust the top position */
  transform: translateY(-50%);
  border-radius: 50%;
  background-image: linear-gradient(90deg, #3BC9AF 0%, #8396FF 50%, #6079FF 100%);
}

.service_section {
  background-color: #d9d9d92b;
}

.service_section ul {
  font-style: 'Roboto' sans-serif;
  font-size: 20px;
}

.service_section .heading_container,
.work_section .heading_container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.service_section .heading_container p,
.work_section .heading_container p {
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #000000;
  opacity: 0.7;
}

.service_section .service_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.service_section .service_container .box {
  margin: 25px 10px;
  min-width: 200px;
  width: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.service_section .img-box {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.service_section .img-box img {
  width: 100%;
}

.service_section .service_container .box .detail-box {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
}

.service_section .service_container .box .detail-box h5 {
  font-weight: bold;
  position: relative;
}

.service_section .service_container .box .detail-box p {
  text-align: left;
  margin-left: 30px
}

.service_section .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.service_section .btn-box a {
  display: inline-block;
  padding: 10px 35px;
  background-color: #2C3E77;
  ;
  color: #FFFFFF;
  border-radius: 10px;
}

.need_section {
  display: flex;
}

.need_section .container .box {
  width: 80%;
  border-radius: 50px;
  background: linear-gradient(90deg, #3BC9AF 0%, #6079FF 100%);
  backdrop-filter: blur(57.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 20px
}

.need_section h2 {
  position: relative;
  font-weight: bold;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 45px;

  color: #FFFFFF;
}

.need_section p {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.9;
}

.need_section a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 25px;
  width: 200px;
  height: 56px;
  background: #FFFFFF;
  border-radius: 999px;
  color: #6079FF;
  font-weight: 600;
  font-size: 20px;
  font-style: normal;
}

.need_section a:hover {
  color: #FFFFFF;
  background: #6079FF;
}

.need_section a:focus {
  outline: none;
}

.work_section {
  color: #2C3E77;

}

.work_section .heading_container {
  margin-bottom: 45px;
}


.work_section .detail_container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  position: relative;
}

.bullet-point {
  display: inline-block;
  margin-top: 75px;
  width: 100%;
  height: 65%;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center left;
  background-image: url(../images/points.svg);
}

.detail_container ul {
  list-style-type: none;
  margin-left: 20px;
}

.detail_container li {
  position: relative;
}

.detail_container li:before {
  content: '\2022';
  /* bullet point character */
  color: #dcdadaf9;
  /* adjust as needed */
  font-size: 150px;
  /* adjust as needed */
  position: absolute;
  left: -80px;
  /* adjust as needed */
  top: 50%;
  transform: translateY(-50%);
}

.work_section .detail_container .box {
  border: 4px solid #EEEEEE;
  background: white;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 32px;
  position: relative;
  z-index: 0;

}

.work_section .box.b-1,
.box.b-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}

.work_section .box::before {
  content: "";
  display: inline-block;
  width: 125px;
  height: 125px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.work_section .b-1::before {
  background-image: url(../images/notepad.svg);
}

.work_section .b-2::before {
  background-image: url(../images/gear.svg);
  ;
}

.work_section .detail_container .box .text-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: left;
}

.work_section .detail_container .box .text-box h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
  margin: 0
}

.work_section .detail_container .box .text-box p {
  font-weight: 400;
  font-size: 22px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.65);
  margin: 0;
}


.footer_bg {
  background-size: cover;
  background-position: right top;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer_bg>* {
  flex-grow: 1
}



.ordering_section .heading_container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.ordering_section {
  padding: 75px;
  background-image: url(../images/order_bg.svg);
  background-repeat: no-repeat;
  background-size: cover
}

.newOrder,.newOrderInfos {
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.06);
  background: #FFFFFF;
  border-radius: 32px;

}
.newOrder{
  padding:15px
}


.newOrderInfos .d-flex{
  background: linear-gradient(90deg, #3BC9AF 0%, #6079FF 100%);
  color:#FFFFFF;
  height:20%;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  align-items: center;
  padding:15px;
}
.newOrderInfos .media {
  align-items: center;
  color: #6079FF;
  font-size: 16px;
}
.newOrderInfos .content{
  padding:15px;
}
.newOrderInfos {
  margin: 0;
  padding: 0;
  list-style: none;
  display:flex;
  flex-direction:column;
}

.newOrderInfos .infos{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
}

.newOrderInfos .infos>div:nth-child(3) {
  grid-column-end: span 2;
  /* Span across two columns */
}
.newOrderInfos .info{
  float: left;
  font-size: 12px;
  margin-top: 20px;
}
.newOrderInfos .info span{
  display: block;
  border-bottom: 1px dashed #707070;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.newOrderInfos .info small{
  display: block;
  color: #6079FF;
  font-size: 12px;
}

.ordering_container .row {
  padding: 20px 0px;
  display: flex; /* Use flexbox for the row layout */
  flex-wrap: wrap;
  
}

.ordering_container .row .col-6 {
  padding: 5px;
  flex: 1; /* Each column takes up an equal amount of space */
}

.service-buttons .service-btn {
  width: 100%;
  padding: 8px 12px;
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  
}

.ordering_container .service-buttons {
  padding: 10px !important;
  /*display: block*/;
  height: 100%;
  gap:3%
}

.ordering_container .service-buttons > div {
  height: calc(25% - 20px);
}

.ordering_container .service_info_col {
  border-radius: 24px;
  background: linear-gradient(90deg, #3BC9AF 0%, #6079FF 100%);
  -webkit-backdrop-filter: blur(57.5px);
  backdrop-filter: blur(57.5px);
  margin: 0 !important;

}

.service-buttons .service-btn.service-active {
  background:rgb(185, 181, 181)
}

.info_contact {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.info_contact .img-box {
  display: flex;
  align-items: center;
  gap: 10px
}



.ordering_section button {
  border-radius: 16px;
  height: 50px;
  border: 2px solid #FFFFFF;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  display: inline-block;
  padding: 15px 35px;
  box-shadow: none;
  outline: none;
}

.ordering_section .submit-btn {
  line-height: 0;
  border-radius: 999px;
  margin-top: 20px;
}

.ordering_section .options{
  display:flex;
  flex-direction: row;
  
}
.custom-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left:30px;
}
.custom-checkbox-text{
text-align: left;
}

.display-checkbox{
  margin-bottom:4px;
}
.ordering_section .add-comment-btn,.ordering_section .remove-comment-btn {
  border: none;
  border-radius:10px;
  width: 30px;
  /* Set width to make the button square */
  height: 30px;
  padding: 5px 10px;
  margin-right: 5px;
}


.ordering_section .control-label {
  font-weight: 500;
}

.ordering_section .form-control {
  background: #FFF;
}

.ordering_section .service-btn {
  color: #000000
}

.ordering_section textarea {
  min-height:100px;
}

.ordering_section .form-row {
  width: 90%;
  margin: 0 auto;
}

.ordering_section .info_col .form-row {
  margin: 0;

}

.info_header {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.info_col .form-group {
  width: 100%;
}

.info_col button {
  border-radius: 16px;
}

.info_col .form-control {
  border: none;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.info_col input,
.info_col input:focus {
  color: rgba(255, 255, 255, 0.8);

}

.info_col .form-control::placeholder {
  opacity: 1;
  color: white;
}

.info_col .control-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.info_col .btn_div {
  display: flex;
  justify-content: right;
}

.info_col .btn_div button {
  background: none;

}

.ordering_section button {
  background: #6079FF;
}



.form-control {
  background: #FCFCFC;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  height: 45px;
}

.control-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}








/* footer section*/
.footer_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.footer_section p {
  color: #FFFFFF;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

.footer_section a {
  color: #FFFFFF;
}

.top-0 {
  top: 0;
}

.end-0 {
  right: 0
}

.hero-character {
  width: 60%;
  position: absolute;
  top: 0;
  max-width: 800px;
}

.hero-character img {
  width: 100%;
}

.ordering_section .info_col input::placeholder {
  opacity: .4;
}

.form-selection .inactive {
  background: #eee;
  color: #0c0c0c;
}

.search-by-id-btn {
  padding: 0 !important;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-item-btn-logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  margin-right: 20px;
  margin-left: 20px;
  flex-shrink: 0;
}

.ordering_section .service-btn {
  text-align: left !important;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
}

.ordering_section .order-item-btn-logo {
  pointer-events: none;
}
#displayPrice{
  padding-top: 10px !important; 
}
@media (max-width: 991px) {
  .ordering_container .service-buttons {
    display: flex;
    height: auto;
    padding: 0 5px !important;
    margin: 5px 0 !important;
    justify-content: center;
  }

  .service-buttons .service-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 20px;
  }

  .ordering_container .service-buttons > div {
    height: auto;
  }

  .service-buttons .service-btn img { 
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  .work_section .box.b-1, .box.b-2 {
    flex-direction: column;
    text-align: center;
  }

  .work_section .box::before {
    width: 70px;
    height: 70px;
  }
  
}

@media (max-width: 767px) {
  .about_section .heading_container h2 {
    text-align: center;
  }

  .about_section .heading_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about_section .detail-box p {
    text-align: center;
  }

  .hero-texts {
    margin-top: -200px;
  }

  .home-title-img .home-title-gradiant-sm {
    width: 400px;
    display: block;
  }
  
  .home-title-img .overlay-img-sm {
    width: 400px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }

  .home-title-img .home-title-gradiant-xl {
    display: none;
  }
  
  .home-title-img .overlay-img-xl {
    display: none;
  }
}

@media (max-width: 576px) {
  .hero-texts {
    margin-top: -150px;
  }

  .home-title-img .home-title-gradiant-sm {
    width: 320px;
    display: block;
  }
  
  .home-title-img .overlay-img-sm {
    width: 320px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }

  .home-title-img .home-title-gradiant-xl {
    display: none;
  }
  
  .home-title-img .overlay-img-xl {
    display: none;
  }
}


/*# sourceMappingURL=style.css.map */