.contact-title {
    font-weight: 700;
    font-size: 45px;
}

.mobile-contact-img {
    text-align: center;
    display: none;
}

.mobile-contact-img img {
    max-width: 250px;
}

.contact .form-control {
    padding-top: 25px;
    padding-bottom: 25px;
}

.form-control {
    border: 1px solid rgba(223, 223, 223, 1) !important;
    box-shadow: none !important;
    background: #FFF;
}

.form-control::placeholder {
    color: rgba(0, 0, 0, 0.35);
}

.contact .submit-btn {
    background: rgba(96, 121, 255, 1);
    color: #FFF;
    border: none;
    outline: none;
    width: 100%;
    padding: 10px 30px;
    font-weight: 500;
    border-radius: 500px;
    margin-top: 30px;
}

.form-control:focus {
    border: 1px solid rgba(96, 121, 255, 1) !important;
}

@media (max-width: 575.98px) {
    .contact-title {
        text-align: center;
        font-size: 35px !important;
        padding-top: 15px;
    }

    .stroke-bg {
        display: flex;
        justify-content: center;
    }

    .contact .left-contact p {
        text-align: center;
        font-size: 18px;
    }

    .contact-page.bg-circle {
        background: url('../../images/contact-mobile-bubbles.png') no-repeat;
        background-position: 0 10px;
        background-size: 100%;
    }

    .mobile-contact-img img {
        max-width: 220px;
    }
}

@media (min-width: 438px) and (max-width: 575.98px) {
    .stroke-bg {
        padding-left: 10%;
        justify-content: left;
    }
}

@media (min-width: 493px) and (max-width: 575.98px) {
    .stroke-bg {
        padding-left: 20%;
        justify-content: left;
    }
}

@media (min-width: 576px) {
    .mobile-contact-img {
        text-align: left;
        padding-left: 50px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .contact-page.bg-circle {
        background: url('../../images/contact-mobile-bubbles.png') no-repeat;
        background-position: 0 20px;
        background-size: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .contact-page.bg-circle {
        background-size: 100% !important;
        background-position: 0 30px;
    }
}

@media (max-width: 1199px) {
    .right-contact {
        display: none;
    }

    .mobile-contact-img {
        display: block;
    }
}

@media (max-width: 2000px) {
    .contact.container {
        width: 100% !important;
        max-width: 100%;
    }
}

@media (min-width: 2000px) {
    .contact.container {
        max-width: 1500px;
    }
}

@media (min-width: 991px) {
    .contact.container {
        padding: 0 65px;
    }
}

@media (min-width: 1500px) and (max-width: 2000px) {
    .contact.container {
        padding: 0 165px;
    }
}