@media (max-width: 1199px) and (min-width: 992px) {
  .team_section .team_container .box.b-3 {
    margin-top: 100px;
  }

  .hero_area .slider_section h2 {
    max-width: 50%;
  }

  .about_section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {

  .hero_area {
    height: auto;
  }

  .navbar-brand {
    margin-left: 0;
  }

  .quote_btn-container {
    flex-direction: column;
    align-items: center;
  }

  .quote_btn-container a {
    display: none;
  }

  .custom_nav-container .nav_search-btn {
    background-position: center;
  }

  .hero_area .slider_section h2 {
    max-width: 50%;
  }

  .hero_area .slider_section .container {
    height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
    max-height: none;
  }

  .hero-character {
    width: 100%;
    display: flex;
    justify-content: end;
    top: 0 !important;
    padding-right: 0 !important;
  }

  .hero-character img {
    height: auto;
    width: 80%;
  }

  .ordering_section {
    padding-left: 12px;
    padding-right: 12px;
  }

  .work_section .container {
    max-width: 100% ;
  }

  .need_section .container {
    max-width: 100%;
  }

  .about_section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .about_section .row {
    align-items: center;
  }
}

@media (max-width: 767px) and (min-width: 576px) {


  .slider_section .detail_box {
    text-align: center;
  }

  .slider_section .container {
    height: auto;
    padding: 45px 0 100px 0;
    max-height: none;
  }

  .hero-text {
    font-size: 65px;
  }

  .hero-character {
    width: 100%;
    position: static;
  }

  .hero-character img {
    height: auto;
    width: 100%;
    top: 0;
  }

  .slider_section .img-box {
    margin-top: 45px;
  }

  .slider_section .carousel_btn-container {
    bottom: 11%;
  }

  .about_section .detail-box {
    margin-top: 45px;
  }

  .about_section .bg-image {
    padding-top: 70px;
  }

  .about_section .img-box {
    margin-top: -115px;
  }

  .work_section .img-box {
    margin-top: 25px;
  }

  .work_section .btn-box {
    justify-content: center;
    margin-top: 35px;
  }

  .team_section .team_container .box.b-2 {
    margin-top: 100px;
  }

  .client_section {
    text-align: center;
    margin-bottom: 45px;
  }

  .client_section .heading_container {
    align-items: center;

  }

  .client_section .box {
    align-items: center;
  }

  .client_section .carousel_btn-container {
    bottom: -100px;
    right: 50%;
    transform: translateX(50%);
  }

  .info_section .row>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    text-align: center;
  }

  .info_section .info_form .social_box {
    justify-content: center;
  }

  .ordering_section {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (max-width: 575.98px) {
  .slider_section .detail_box h1 {
    font-size: 3.5rem;
  }

  .slider_section .container {
    height: auto;
    padding-top: 0;
    padding-bottom: 70px;
    max-height: none;
  }

  .hero-text {
    font-size: 65px;
  }

  .hero-character {
    width: 100%;
    position: static;
  }

  .hero-character img {
    height: auto;
    width: 100%;
    top: 0;
  }

  h2 {
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .service_section .heading_container p, .work_section .heading_container p {
    font-size: 20px;
  }

  .need_section .container {
    width: 100%;
  }

  .need_section p {
    font-size: 20px;
  }

  .work_section .detail_container .box .text-box h5 {
    font-size: 20px;
  }

  .work_section .detail_container .box .text-box p {
    font-size: 16px;
  }

  .ordering_section {
    padding-left: 12px;
    padding-right: 12px;
  }

  .ordering_section .info_header {
    gap: 10px;
  }

  .ordering_section .info_col h3 {
    font-size: 20px;
  }

  .ordering_section .info_col p {
    font-size: 14px;
  }

  .ordering_section .form-row {
    width: 100%;
  }

  .ordering_section .info_col {
    gap: 20px;
  }

  .ordering_section .info_col button {
    margin-top: -15px;
  }

  .form-switch-btns {
    flex-direction: column !important;
  }

  .form-switch-btns button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {}

@media (max-width: 420px) {
  .slider_section .detail_box h1 {
    font-size: 3rem;
  }
}

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1600px) {
  .hero-character {
    width: 80%;
    position: absolute;
    max-width: 1000px;
  }
}

@media (min-width: 2500px) {

  .hero-character {
    right: -15%;
  }

  .slider_section .container {
    position: relative;
  }
}

@media (min-height: 1000px) and (min-width: 1000px) {
  .hero-character {
    top: 50%;
    transform: translateY(-50%);
  }
}