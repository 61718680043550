.condition_section .heading_container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 45px;
}

.condition_section {
    padding: 75px;
    background-image: url(../../images/order_bg.svg);
    background-repeat: no-repeat;
    background-size: cover
}

.condition_container {
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.06);
    background: #FFFFFF;
    border-radius: 32px;

}

.condition_container .row {
    padding: 10px;
}
.accordion_box{
    width:100%;
}

.accordion{
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    padding:45px 45px;
}
.accordion-item{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.06);
    background: #FFFFFF;
    border-radius: 30px;
    margin:30px 0; 

}

.accordion button{
    margin:0;
    display:flex;
    justify-content: space-between;
    align-items:center;
    background: #6079FF;
    color: black;
    font-weight: bold;
    font-size: 20px;
    height:80px;
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.06);
    width:100%;
    transition: border-radius 0.15s ease-in-out;
    border:none;
    border-radius:30px;
        }
.accordion button:focus{
    outline:none
}
.accordion button::after {
    right: 10px;
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all .35s;
    transform: rotate(90deg);
        
}
.accordion button:not(.collapsed){
    border-bottom-left-radius: 0px;
    border-bottom-right-radius:0px ;
}

.accordion button.collapsed::after {
    transform: rotate(0deg);
}

.question{
    margin-bottom:0;
}
.question_title{
    margin-left:10px;
}
.answer{
    color:black;
    padding:20px;
}
.answer ul{
    list-style-type: disc;
}
.answer li{
    margin-bottom:20px;
}

@media (max-width: 768px) {
    .condition_section {
        padding-right: 12px;
        padding-left: 12px;
    }

    .condition_section .accordion {
        padding-left: 10px;
        padding-right: 10px;
    }
}
