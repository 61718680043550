.orders_section {
    padding: 75px;
    background-repeat: no-repeat;
    background-size: cover
}

.orders_section .heading_container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 25px;
}

.orders_section .row {
    padding: 20px;

}

.orders_container {
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.06);
    background: #FFFFFF;
    border-radius: 32px;
    
}

.orders_box {
    width: 100%;
    background: white;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 0 10px;

}

.orders_box::-webkit-scrollbar {
    border-radius: 100px;
    width: 6px;
    height: 6px;
    padding: 10px;

}

.orders_box::-webkit-scrollbar-thumb {
    background: #F3F3F3;
    border-radius: 100px;
    width: 5px;
    height: 20px;
    padding: 10px;
}

table {
    background: white;
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;
}

.table th,
.table td {
    border: none !important;
}


tr {
    height: 44px;
}

th,
td {
    line-height: 20px;
}

th {
    text-align: center;
}

tbody {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.154px;
    color: #70768C;

}

thead {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    color: #4F5E74;
}

thead th {
    border: none !important;
    text-align: left;

}

tbody td {
    text-align: left;
    background: #FBFBFB;

}

tbody tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

tbody tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
}

.orders_container td h6 {
    color: #4F5E74;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    display: none;
}

.orders_container td h6 {
    color: #4F5E74;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    display: none;
}

@media (max-width: 991px) {
    .orders_section {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media (max-width: 575.98px) {

    .orders_container td h6 {
        display: block;
    }

    .orders_section thead {
        display: none;
    }

    .orders_container {
        padding: 5px;
    }

    .without-padding {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0;
        padding-top: 0 !important;
    }

    .orders_container tr {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: auto;
        margin-bottom: 20px;
        border-radius: 20px;
        background: #FBFBFB;
        padding: 10px;
    }

    .col-span {
        grid-column: span 2;
    }

    .orders_container td {
        display: inline-block;
        width: 100%;
        border-radius: 0;
        background: transparent;
    }

}

