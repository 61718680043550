.footer-main .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 
}

.footer-left {
    display: flex;
    gap: 5px;
    white-space: nowrap;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
}
.footer-left p{
    margin-bottom: 0
}
.footer-left a {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    color: #000000;
    font-size: 16px;
}

.footer-left span {
    font-size: 16px;
}

.footer-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10%;
    white-space: nowrap;
}

.footer-right p {
    margin-bottom: 0;
    font-weight: 550;
    font-size: 14px;
    line-height: 140%;
    color: #6079FF;
}

.footer-main .img-box {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.footer-main .img-box img {
    margin-right: 5px;
}

.upper-footer {
    background: #000;
    padding-top: 30px;
}

.footer-description {
    color: #FFF;
    font-weight: 300;
    margin: 40px 0;
}

.footer-social {
    gap: 35px;
}

.footer-links {
    color: #FFF;
}

.footer-links li {
    margin-bottom: 20px;
}

.footer-links li {
    font-size: 18px;
}

.footer-links li a {
    color: #FFF;
}

.footer-links .link-icon {
    margin-right: 15px;
}

.footer-service-title {
    text-transform: uppercase;
    font-size: 20px;
    padding-bottom: 10px;

}

.footer-bottom-links {
    display: flex;
    justify-content: center;
    gap: 5px;
}

@media (max-width: 768px) {
    .footer-main .container {
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .footer-left {
        flex-direction: column;
        gap: 5px;
    }

    .footer-right {
        justify-content: center;
    }

    .footer-right .img-box {
        margin-left: 0;
    }

    .footer-links li {
        font-size: 16px;
    }

    .footer-service-title {
        font-size: 18px;
    }

    .footer-left p {
        margin-bottom: 10px;
    }

    .footer-bottom-links span:first-child {
        display: none;
    }
}

@media (max-width: 991px) and (min-width: 768px) { 
    .footer-main .container {
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .footer-right {
        justify-content: center;
    }

    .footer-right .img-box {
        margin-left: 0;
        width: auto;
    }
}
