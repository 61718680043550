.navbar {
    padding: 0 150px;
    box-shadow: 0 0 30px 10px rgba(0 , 0, 0, .03);
}

.header_section a{
    display:flex;
    gap:10px;
    align-items:center;
}
.site_title{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: #0A0A0A;
    margin-right: 50px;
}

.logo-img {
    width: 80px;
    height: 80px;
}

.sign_in{
    width:fit-content;
    display:flex;
    gap:10px;
    margin-top:0;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #0A0A0A;
}

.sign_in button {
    margin-right: 0px !important;
}


.sub_page .hero_area {
    height: auto;
    background: none;
    background-color: #041858;
}

.sub_page .footer_bg {
    background: none;
    background-color: #041858;
    padding-top: 45px;
}

.header_section .container-fluid {
    padding-right: 0px;
    padding-left: 0px;
}

.header_section .nav_container {
    margin: 0 auto;
}
.nav_links{
    margin-right:0 !important;
}
.custom_nav-container .navbar-nav .nav-item .nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #0A0A0A;
    margin-left: 30px;
    margin-right: 30px;
}

.custom_nav-container .sign_in {
    font-size: 14px;
}


/*.custom_nav-container .navbar-nav .nav-item.active .nav-link,
.custom_nav-container .navbar-nav .nav-item:hover .nav-link {
    background-color: #60AFFF;
}*/

a,
a:hover,
a:focus {
    text-decoration: none;
}

a:hover,
a:focus {
    color: initial;
}

.btn,
.btn:focus {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom_nav-container .nav_search-btn {
    background-image: url(../../images/search-icon.png);
    background-size: 22px;
    background-repeat: no-repeat;
    background-position-y: 7px;
    width: 35px;
    height: 35px;
    padding: 0;
    border: none;
}

.navbar-brand {
    margin-left: 25px;
}

.navbar-brand span {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
}

.custom_nav-container {
    z-index: 1;
}

.navbar-expand-lg .navbar-collapse {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.custom_nav-container .navbar-toggler {
    outline: none;
}

.custom_nav-container .navbar-toggler {
    padding: 0;
    width: 37px;
    height: 42px;
}

.custom_nav-container .navbar-toggler span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: #ffffff;
    margin: 7px 0;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0;
    margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
    display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin: 0;
    margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
    -webkit-transform: none;
    transform: none;
}

.quote_btn-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.quote_btn-container a {
    color: #ffffff;
    margin-right: 25px;
}

.quote_btn-container a img {
    width: 15px;
    margin: 0 25px;
}

ul {
    list-style-type: none;
}

.auth-dropdown {
    background: #FFF !important;
    border-radius: none !important;
    color: #0A0A0A;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
    border: none;
    margin-right: 0 !important;
    margin-left: 20px;
    margin-bottom:0
}

.auth-dropdown:hover, .auth-dropdown:focus, .auth-dropdown:active {
    background: #ddd !important;
    color: #0A0A0A !important;
    box-shadow: none !important;
}

.auth-dropdown:not(:disabled):not(.disabled):active, .auth-dropdown:not(:disabled):not(.disabled).active, .show > .auth-dropdown.dropdown-toggle {
    color: #0A0A0A !important;
}

.nav-item.nav-link-active, .nav-item.nav-link-active a {
    color: #6079FF !important;
    position: relative;
}

.nav-item.nav-link-active::after {
    position: absolute;
    width: calc(100% - 60px);
    height: 2px;
    background: #6079FF;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
}

@media (max-width: 768px) {
    .navbar-collapse {
        transition: .4s;
        visibility: visible;
        opacity: 1;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 3px 5px 0px rgba(0 , 0, 0, .1);
        padding: 10px 0 20px 0;
    }
    .navbar-collapse.collapsed {
        visibility: hidden;
        opacity: 0;
    }

    .nav-item.nav-link-active::after {
        width: calc(100% - 30px);
    }

    .site_title{
        font-size: 20px !important;
    }
    
    .logo-img {
        width: 40px !important;
        height: 40px !important;
    }
    .nav-item .dropdown-basic.btn {
        margin-left: 0 !important;
    }
}

@media (max-width: 992px) {

    .navbar {
        padding: 0 30px;

    }

    .navbar .container {
        max-width: 100% !important;
    }

    .custom_nav-container .navbar-nav .nav-item .nav-link {
        font-size: 14px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .nav-item .dropdown {
        display:flex;
        justify-content:center;
        
    }
    .auth-dropdown span {
        max-width: 150px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .auth-dropdown {
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .site_title{
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        color: #0A0A0A;
        margin-right: 50px;
    }
    
    .logo-img {
        width: 60px;
        height: 60px;
    }

    .nav-item.nav-link-active::after {
        width: calc(100% - 30px);
    }
}

@media (max-width: 1500px) and (min-width: 992px) { 
    .navbar {
        padding: 0 50px;
    }

}

/*end header section*/